.App {
  text-align: center;
}

/* app.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: white;
  background-color: #000000;
}

.container {

margin-left: 100px;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #353535;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  float: left;
  margin-left: 20px;
}
.name {

  font-size: larger;
}


.note-box {
  border: 1px solid #8f1b1b;
  background-color: #2f2f2f;
  border-radius: 5px;

  padding: 5px;
  margin-bottom: 10px;
}
.notes {
  width: 500px;
  margin: 20px auto;
  margin-right: 100px;
  padding: 10px;
  background-color: #2f2f2f;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  float: right;
margin-right: 20px;

}

input[type="text"],
textarea,
button {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form {
  color: rgb(89, 174, 32);
  position: fixed;
  top: 50%;
  font-family: 'Courier New', Courier, monospace;
font-size: small;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  margin: auto;
}

button {
  background-color: rgb(89, 174, 32);
  color: #000000;
  font-weight: 900;
  border: none;
  cursor: pointer;
  max-width: 100px;
}

.note{
  background-color: rgb(22, 22, 22);
  max-width: 200px;
  padding: 50px;
 margin: auto;

 margin-bottom: 5px;
 border-radius: 10px;
}

button:hover {
  background-color:  rgb(73, 117, 41);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.header {
  display: flex;
  justify-content: center;
  margin: auto;
  position: fixed;
  align-items: center;
  padding: 20px;
}

.logo {
  width: 25px; /* Adjust size as needed */
  margin: 0 10px; /* Add margin between logos */
  cursor: pointer; /* Change cursor to pointer for better UX */
}